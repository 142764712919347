import { patientProgressAtom } from '~modules/state';
import { ADDRESS_PAGE_NAME, ALL_SET_PAGE_NAME, PHARMACY_PAGE_NAME, REFERRAL_PAGE_NAME } from '~types';

import type { RoutingConfig, StackParamList } from '~types';

export const accountSetupRoutes = {
  id: 'accountSetup' as const,
  initialAsync: async getState => {
    const patientProgressState = await getState(patientProgressAtom);

    if (!patientProgressState.pharmacy) return { name: PHARMACY_PAGE_NAME };
    if (!patientProgressState.address) return { name: ADDRESS_PAGE_NAME };
    if (!patientProgressState.referral) return { name: REFERRAL_PAGE_NAME };

    return { name: ALL_SET_PAGE_NAME };
  },
  routes: {
    [PHARMACY_PAGE_NAME]: {
      on: {
        submit: async getState => {
          const patientProgressState = await getState(patientProgressAtom);

          if (!patientProgressState.address) return { name: ADDRESS_PAGE_NAME };
          if (!patientProgressState.referral) return { name: REFERRAL_PAGE_NAME };

          return { name: ALL_SET_PAGE_NAME };
        },
      },
    },
    [ADDRESS_PAGE_NAME]: {
      on: {
        submit: async getState => {
          const patientProgressState = await getState(patientProgressAtom);

          if (!patientProgressState.referral) return { name: REFERRAL_PAGE_NAME };

          return { name: ALL_SET_PAGE_NAME };
        },
      },
    },
    [REFERRAL_PAGE_NAME]: {
      on: {
        submit: () => ({ name: ALL_SET_PAGE_NAME }),
      },
    },
    [ALL_SET_PAGE_NAME]: {
      reset: true,
      on: null,
    },
  },
} satisfies RoutingConfig<StackParamList>;
