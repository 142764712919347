import * as axiosBase from '../axiosBase';
import { PATIENTS_PATH } from '../config';
import {
  CONSENTS_PATH,
  CREATE_PATIENT_PATH,
  GET_INSURANCE_PATH,
  HISTORIES_PATH,
  LAB_ORDERS_PATH,
  PHARMACIES_PATH,
  QUESTIONNAIRES_PATH,
  REFERRAL_PATH,
  SUBMIT_QUESTIONNAIRE_PATH,
  SUBSCRIPTIONS_PATH,
  SYSTEM_MESSAGES_PATH,
  TEXT_MESSAGES_PATH,
  TYPEFORM_PATH,
} from './config';

import type { MessageTypeEnum } from '@almond/api-types';
import type { AxiosResponse } from 'axios';

export const retrieve = () => axiosBase.get(PATIENTS_PATH);

export const createPatient = (profileUuid: string) => axiosBase.post(CREATE_PATIENT_PATH(profileUuid))();

export const saveHistory = axiosBase.patch(HISTORIES_PATH);

export const completeHistory = axiosBase.put(HISTORIES_PATH);

export const addReferral = axiosBase.post(REFERRAL_PATH);

export const createPharmacy = axiosBase.post(PHARMACIES_PATH);

export const createSubscription = axiosBase.post(SUBSCRIPTIONS_PATH);

export const getSubscription = () => axiosBase.get(SUBSCRIPTIONS_PATH);

export const getLabOrders = () => {
  return axiosBase.get(LAB_ORDERS_PATH, { params: { status: 'outstanding', scheduled: false } });
};

export const createConsent = (fileUri: string): Promise<AxiosResponse> => axiosBase.uploadFile(fileUri, CONSENTS_PATH);

export const retrieveInsurance = () => axiosBase.get(GET_INSURANCE_PATH);

export const createQuestionnaire = axiosBase.post(QUESTIONNAIRES_PATH);

export const saveQuestionnaire = (questionnaireUuid: string) =>
  axiosBase.patch(SUBMIT_QUESTIONNAIRE_PATH(questionnaireUuid));

export const completeQuestionnaire = (questionnaireUuid: string) =>
  axiosBase.put(SUBMIT_QUESTIONNAIRE_PATH(questionnaireUuid));

export const submitTypeformResponse = axiosBase.post(TYPEFORM_PATH);

export const sendSystemMessage = (messageType: MessageTypeEnum) => axiosBase.post(SYSTEM_MESSAGES_PATH(messageType))();

export const sendTextMessage = (messageType: MessageTypeEnum) => axiosBase.post(TEXT_MESSAGES_PATH(messageType))();
