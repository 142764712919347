import React from 'react';

import { useTranslation } from '@almond/localization';
import { useBrowserTypeMap, useTheme } from '@almond/ui';

import { Container } from '~modules/layout';
import { MessageWithImageV2 } from '~modules/ui';

import themedStyles from './styles';

export const ConfirmEmailPage: React.FC = () => {
  const { t } = useTranslation();
  const [styles] = useTheme(themedStyles);
  const { isDesktop } = useBrowserTypeMap();

  return (
    <Container id="confirmEmail" contentContainerStyle={styles.contentContainer} shouldHideCloseButton>
      <MessageWithImageV2
        image="womanLookingAhead"
        imageDimensions={{ width: 463, height: 408 }}
        mobileImageDimensions={{ width: 201, height: 177 }}
        title={t('confirmEmail.message.title')}
        subtitle={t('confirmEmail.message.subtitle')}
        messageContainerStyle={isDesktop && styles.messageContainer}
      />
    </Container>
  );
};
