import React from 'react';
import { useWindowDimensions, View } from 'react-native';

import { ScrollView, Text, useAdaptive, useBrowserType, useTheme } from '@almond/ui';

import { BackButton, NavigationBar } from '~modules/integration';

import themedStyles from './styles';

import type { ParseKeys } from '@almond/localization';
import type { NavigationBarProps } from '~modules/integration';

export type ContainerWithSidebarProps = Omit<
  NavigationBarProps,
  'isAdminHost' | 'isLoading' | 'isAbsoluteOnDesktop'
> & {
  id: `${ParseKeys}${string}`;
  content: React.ReactNode;
  sidebarContent: React.ReactNode;
  title: React.ReactNode;
  subtitle?: React.ReactNode;
  mobileOrderReversed?: boolean;
  isLoadingNavigationBar?: boolean;
  statusBar?: React.ReactNode;
};

const NARROW_DESKTOP = 700;

export const ContainerWithSidebar: React.FC<ContainerWithSidebarProps> = props => {
  const [styles] = useTheme(themedStyles);

  const { width } = useWindowDimensions();
  const isMobile = useBrowserType() === 'mobile';
  const containerStyles = useAdaptive(styles.container, [styles.container, styles.mobileContainer]);
  const layoutStyle = useAdaptive(
    props.mobileOrderReversed ? styles.horizontalReverse : styles.horizontal,
    styles.vertical
  );
  const contentContainerStyle = useAdaptive(
    [styles.contentContainer, styles.contentContainerDesktop],
    styles.contentContainer
  );
  const sidebarContainerStyle = useAdaptive(styles.sidebarContentContainerDesktop, null);
  const titleContainerStyle = useAdaptive(styles.titleContainer, [styles.titleContainer, styles.titleContainerMobile]);

  const sidebarContent = (
    <View
      style={[
        styles.sidebarContentContainer,
        sidebarContainerStyle,
        width < NARROW_DESKTOP && !isMobile && styles.sidebarContentContainerNarrow,
      ]}
    >
      {props.sidebarContent}
    </View>
  );

  return (
    <View style={containerStyles}>
      {props.statusBar}
      {isMobile && (
        <NavigationBar
          shouldHideCloseButton={props.shouldHideCloseButton}
          onBack={props.onBack}
          isLoading={props.isLoadingNavigationBar}
          bannerText={props.bannerText}
          isAbsoluteOnDesktop={true}
        />
      )}
      <ScrollView contentContainerStyle={[styles.innerContainer, layoutStyle]}>
        {props.mobileOrderReversed && sidebarContent}
        <View style={contentContainerStyle}>
          {!isMobile && <BackButton onBack={props.onBack} style={styles.desktopBack} />}
          <View style={titleContainerStyle}>
            {typeof props.title === 'string' ? (
              <Text size="xxxxl" testID={`${props.id}:${props.title}`}>
                {props.title}
              </Text>
            ) : (
              props.title
            )}
            {typeof props.subtitle === 'string' ? (
              <Text testID={`${props.id}:${props.subtitle}`} style={styles.subtitle}>
                {props.subtitle}
              </Text>
            ) : (
              props.subtitle
            )}
          </View>
          <View style={styles.contentInnerContainer}>{props.content}</View>
        </View>
        {!props.mobileOrderReversed && sidebarContent}
      </ScrollView>
    </View>
  );
};
