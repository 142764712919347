export type StringifiedBoolean = 'true' | 'false';

type MarketingParams = {
  utm_campaign: string;
  utm_content: string;
  utm_medium: string;
  utm_source: string;
  utm_term: string;
  fbclid: string;
  _gl: string;
};

type ArtemisParams = {
  is_admin: StringifiedBoolean;
  artemis_redirect_url: string;
};

type AppointmentParams = {
  is_new_member: StringifiedBoolean;
  visit_reason: string;
  appointment_uuid: string;
  booking_uuid: string;
  showAll: StringifiedBoolean;
  category: string;
};

type PatientParams = {
  patient_uuid: string;
};

type ProfileParams = {
  profile_uuid: string;
  email: string;
};

type ReferralParams = {
  refer: string;
  code: string;
};

export type BaseParamsWithoutRouting = Partial<
  MarketingParams & ArtemisParams & AppointmentParams & PatientParams & ProfileParams & ReferralParams
>;
