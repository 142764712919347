import { cypressUtilities } from '@almond/utils';

import { isOriginAllowed, originMessage, typeformMessage } from '../config';

import type { z } from 'zod';

type Callback = (...args: [error: null, value: z.infer<typeof typeformMessage>] | [error: Error, value: null]) => void;

export const TIMEOUT_DURATION = 2000;

export const listenForMessage = (
  callback: Callback,
  thisWindow: Pick<Window, 'location' | 'addEventListener' | 'removeEventListener'> = window,
  timeoutDuration = TIMEOUT_DURATION
) => {
  let timeout: NodeJS.Timeout | undefined;

  const startTimeout = () => {
    timeout = setTimeout(() => {
      callback(
        new Error(
          // eslint-disable-next-line max-len
          'Your results have been saved, but there was a problem completing your booking. Please contact your care team for assistance.'
        ),
        null
      );
    }, timeoutDuration);
  };

  const onMessage = (event: MessageEvent) => {
    if (event.origin !== thisWindow.location.origin && !isOriginAllowed(event.origin)) {
      return;
    }

    // Origin message
    if (event.data === 'request_origin') {
      try {
        event.source?.postMessage(
          originMessage.parse({ almondMessage: true, type: 'origin', origin: thisWindow.location.origin }),
          {
            targetOrigin: event.origin,
          }
        );
        startTimeout();
      } catch (e) {
        callback(e instanceof Error ? e : new Error('Error listening for message'), null);
        clearTimeout(timeout);
        thisWindow.removeEventListener('message', onMessage);
      }
    }

    // Typeform message
    const parsedMessage = typeformMessage.safeParse(event.data);

    if (parsedMessage.success) {
      callback(null, parsedMessage.data);
      clearTimeout(timeout);
      thisWindow.removeEventListener('message', onMessage);
    }
  };

  thisWindow.addEventListener('message', onMessage);

  return () => {
    thisWindow.removeEventListener('message', onMessage);
  };
};

export const sendMessageToParentFrame = async (
  data: z.infer<typeof typeformMessage>,
  thisWindow: Pick<Window, 'parent' | 'addEventListener' | 'removeEventListener'> = window,
  timeoutDuration = TIMEOUT_DURATION
) => {
  // Using window.parent instead of window.top to support Cypress
  // Cypress mocks window.parent weirdly too, so only verify top window if not Cypress
  if (!cypressUtilities.isCypressRunning()) {
    if (!thisWindow.parent || thisWindow.parent === thisWindow) {
      throw new Error('Current window is already in top frame');
    }
  }

  return new Promise<void>((resolve, reject) => {
    // Get parent frame's origin. If it's in the allow list, send the message
    // to that origin.
    thisWindow.parent.postMessage('request_origin', '*');
    let timeout: NodeJS.Timeout | undefined;
    const listener = (event: MessageEvent) => {
      const originMessageParsed = originMessage.safeParse(event.data);

      if (!originMessageParsed.success) {
        // Message came from elsewhere (maybe the StripeJS library)
        return;
      }

      const { origin } = originMessageParsed.data;

      if (isOriginAllowed(originMessageParsed.data.origin)) {
        thisWindow.removeEventListener('message', listener);
        thisWindow.parent.postMessage(data, origin);
        resolve();
      } else {
        // Parent frame is of wrong origin
        reject(new Error(`Could not send message to parent frame - disallowed origin ${origin}`));
      }

      clearTimeout(timeout);
    };

    thisWindow.addEventListener('message', listener);
    timeout = setTimeout(() => {
      thisWindow.removeEventListener('message', listener);
      reject(new Error('Could not send message to parent frame - timeout'));
    }, timeoutDuration);
  });
};
