import { useCallback } from 'react';

import { useFocusEffect } from 'expo-router';
import { useRecoilState } from 'recoil';

import { productsApi } from '~modules/api';
import { creditCardAtom } from '~modules/state';

export const useReapplyFirstMonthFree = (isResubscribe: boolean) => {
  const [creditCardState, setCreditCardState] = useRecoilState(creditCardAtom);

  useFocusEffect(
    useCallback(() => {
      if (isResubscribe) {
        if (creditCardState.promotionCode?.code === 'firstmonthfree') {
          setCreditCardState(prevState => ({
            ...prevState,
            promotionCode: undefined,
          }));
        }

        return;
      }

      if (!creditCardState.promotionCode || creditCardState.promotionCode.state === 'not-found') {
        // Using .then() for simplicity - this is hardcoded to always resolve
        productsApi.checkPromotionCode('firstmonthfree').then(promotionCode => {
          setCreditCardState(prevState => ({
            ...prevState,
            promotionCode: { ...promotionCode, state: 'success' },
          }));
        });
      }
    }, [creditCardState.promotionCode, isResubscribe, setCreditCardState])
  );
};
