.container {
  background: var(--color-light-error);
  padding: 0.25rem;
  text-align: center;
}
.containerSandbox {
  background: var(--color-info);
}
.button {
  appearance: none;
  border: 0;
  background: none;
  padding: 0;
  cursor: pointer;
}

.button:hover {
  background: rgba(0, 0, 0, 0.1);
}
