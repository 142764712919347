import React from 'react';

import { VisitReasonsPage } from '~modules/appointment';
import { Container } from '~modules/layout';
import { withRouteBehavior } from '~modules/navigation';
import { configurations } from '~modules/routing';

export default withRouteBehavior(VisitReasonsPage, {
  allowDirectRouting: true,
  routingConfig: searchParams => {
    const isNewMember = searchParams.get('is_new_member') !== 'false';

    if (isNewMember) {
      return configurations.initialNewMember;
    }

    return configurations.initial;
  },
  restoreAuth: true,
  clearAuth: true,
  loadingEle: <Container id="visitReasons" isLoading />,
  title: 'Visit Reasons',
});
