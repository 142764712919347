/* eslint-disable react/jsx-no-literals */
import React from 'react';

import { Text } from '@almond/ui';
import clsx from 'clsx';

import styles from './SandboxBanner.module.css';

import type { Dispatch, SetStateAction } from 'react';

type SandboxBannerProps = {
  isSandbox: boolean;
  setIsSandbox: Dispatch<SetStateAction<boolean>>;
};

export const SandboxBanner = (props: SandboxBannerProps) => {
  const copy = props.isSandbox
    ? 'form is in sandbox mode (no data will be submitted)'
    : 'form is LIVE (data WILL be submitted, tagged with "is_test=true")';

  return (
    <div className={clsx(styles.container, props.isSandbox && styles.containerSandbox)}>
      <Text size="m">Test environment: {copy}.</Text>
      <button className={styles.button} onClick={() => props.setIsSandbox(p => !p)}>
        <Text size="m">Click here to {props.isSandbox ? 'disable' : 're-enable'} sandbox mode.</Text>
      </button>
    </div>
  );
};
