import React, { useMemo } from 'react';

import { useTheme } from '@almond/ui';
import { Elements as StripeElements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { useRecoilValue } from 'recoil';
import useSWRImmutable from 'swr/immutable';

import env from '~env';
import { PRODUCTS_PATH, productsApi } from '~modules/api';
import { ErrorMessage } from '~modules/errors';
import { creditCardAtom } from '~modules/state';

import { calculateDiscountedTotalPrice } from '../../services';

import type { StripeElementsOptions } from '@stripe/stripe-js';

const stripePromise = loadStripe(env.STRIPE_API_KEY);
const boxShadow = '0px 4px 5px 0px rgba(0,0,0,0.2)';

export const Elements = (props: React.PropsWithChildren) => {
  const { children } = props;
  const creditCardState = useRecoilValue(creditCardAtom);
  const { data, error, mutate } = useSWRImmutable(PRODUCTS_PATH, productsApi.getProducts);
  const [theme] = useTheme();

  const amount = useMemo(() => {
    if (!data) return;

    return calculateDiscountedTotalPrice(data, [creditCardState.promotionCode, creditCardState.friendReferralCode]);
  }, [creditCardState.friendReferralCode, creditCardState.promotionCode, data]);
  const options: StripeElementsOptions = useMemo(
    () =>
      ({
        mode: 'subscription',
        amount: amount ?? 0,
        currency: 'usd',
        paymentMethodCreation: 'manual',
        appearance: {
          theme: 'flat',
          variables: {
            colorPrimary: theme.primary,
            colorBackground: theme.background,
            colorText: theme.text,
            colorSuccess: theme.info,
            colorDanger: theme.error,
            colorWarning: theme.lightError,
          },
          labels: 'floating',
          rules: {
            // eslint-disable-next-line @typescript-eslint/naming-convention
            '.Input': {
              boxShadow,
            },
            // eslint-disable-next-line @typescript-eslint/naming-convention
            '.Tab': {
              boxShadow,
            },
            // eslint-disable-next-line @typescript-eslint/naming-convention
            '.TermsText': {
              // Hiding default TermsText to display a custom one
              fontSize: '0',
            },
          },
        },
        paymentMethodTypes: ['card'],
      }) as const,
    [amount, theme.background, theme.error, theme.info, theme.lightError, theme.primary, theme.text]
  );

  if (error) {
    return <ErrorMessage error={error} onTryAgain={() => mutate()} />;
  }

  return (
    <StripeElements stripe={stripePromise} options={options}>
      {children}
    </StripeElements>
  );
};
