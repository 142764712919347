import * as axiosBase from '../axiosBase';
import { CALCULATE_PRICE_PATH, PRODUCTS_PATH, PROMOTION_CODES } from './config';

import type { PromotionCodeOut } from '@almond/api-types';

export const getProducts = async () => {
  const result = await axiosBase.get(PRODUCTS_PATH, { params: { lookup_keys: ['basic_annual_membership'] } });

  // In the near future we will be expanding this API call to
  // fetch multiple different products for the prospective member
  // to choose from. Making this quick-and-dirty in the meantime
  return result.products[0];
};

export const calculatePrice = (priceId: string) => axiosBase.post(CALCULATE_PRICE_PATH(priceId));

export const checkPromotionCode = async (code: string) => {
  if (code.toLowerCase() === 'firstmonthfree') {
    // Hardcode "fake" promotion code
    return {
      amountOff: 0,
      id: '',
      promotionCodeType: 'stripe_discount',
      code,
    } satisfies PromotionCodeOut & { code: string };
  }

  const result = await axiosBase.get(PROMOTION_CODES, { params: { code: code.toLowerCase() } });

  return {
    ...result,
    code,
  };
};
