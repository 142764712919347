/* eslint-disable max-statements */
import React, { useCallback, useState } from 'react';

import { useTrackAnalyticsEvent } from '@almond/analytics';
import { useTranslation } from '@almond/localization';
import {
  ConnectedCheckbox,
  ConnectedDateInput,
  ConnectedPhoneNumberInput,
  ConnectedTextInput,
  SmsLabel,
  Text,
  useTheme,
} from '@almond/ui';
import { useFocusEffect, useRouter } from 'expo-router';

import { type HttpRequestError } from '~modules/errors';
import { MainForm } from '~modules/forms';
import { useAsync } from '~modules/promises';
import { useRouteNavigation } from '~modules/routing';
import { demographicAtom } from '~modules/state';

import { useCreateProfile, useRetrieveByEmail, useRetrieveByPhone } from '../../hooks';
import { formatPhoneNumber } from '../../services';
import FormError from './FormError';
import { validationSchema } from './validations';

import themedStyles from './styles';

import type { FormValues } from '@almond/ui';
import type { DEMOGRAPHIC_PAGE_NAME, DemographicFormValues } from '~types';

export const DemographicPage: React.FC = () => {
  const { t } = useTranslation();
  const [styles] = useTheme(themedStyles);
  const { dispatch } = useRouteNavigation<typeof DEMOGRAPHIC_PAGE_NAME>();
  const { doAsync, isLoading } = useAsync();
  const trackAnalyticsEvent = useTrackAnalyticsEvent();
  const [error, setError] = useState<HttpRequestError>();
  const createProfile = useCreateProfile();
  const retrieveByPhone = useRetrieveByPhone();
  const retrieveByEmail = useRetrieveByEmail();
  const [patientUuid, setPatientUuid] = useState<string | null>(null);
  const { setParams } = useRouter();

  const handleSubmit = useCallback(
    (values: FormValues<DemographicFormValues>) => {
      const toCall = async (): Promise<void> => {
        setError(undefined);
        const formattedPhoneNumber = formatPhoneNumber(values.phone);
        const profileData = { ...values, phone: formattedPhoneNumber };

        // If a user enters the same first and preferred name, clear the preferred name
        // We fall back on first name when preferred name is empty
        if (profileData.firstName === profileData.preferredFirstName) {
          profileData.preferredFirstName = '';
        }

        try {
          const response = await createProfile(profileData);

          // Track event after createProfile() is successful because
          // if it's a returning patient it will fail and we don't want to track it
          trackAnalyticsEvent('profile_created');
          await dispatch('submit', undefined, response);
        } catch (errorResponse: any) {
          if (!['account_already_exists', 'display_to_user'].includes(errorResponse?.errorCode)) {
            throw errorResponse;
          }

          if (errorResponse?.errorCode === 'display_to_user') {
            setError(errorResponse);

            return;
          }

          try {
            setPatientUuid(null);
            const [emailResult, phoneResult] = await Promise.all([
              retrieveByEmail(values.email),
              retrieveByPhone(formattedPhoneNumber),
            ]);

            const foundPatientuuid = emailResult?.uuid || phoneResult?.uuid;

            if (foundPatientuuid) {
              setPatientUuid(foundPatientuuid);
            }

            setError(errorResponse);
          } catch (e) {
            setError(errorResponse);
          }
        }
      };

      doAsync(toCall);
    },
    [doAsync, createProfile, trackAnalyticsEvent, dispatch, retrieveByEmail, retrieveByPhone]
  );

  // Resetting all the session specific params on this page.
  // These params should be reset if we refreshed the current page during the pre-booking flow.
  useFocusEffect(
    useCallback(() => {
      setParams({
        profile_uuid: undefined,
        patient_uuid: undefined,
        appointment_uuid: undefined,
        booking_uuid: undefined,
        email: undefined,
      });
    }, [setParams])
  );

  return (
    <MainForm
      id="demographic"
      title={t('demographic.title')}
      submitButtonTitle={t('continue')}
      atom={demographicAtom}
      onSubmit={handleSubmit}
      isLoading={isLoading}
      formError={<FormError error={error} patientUuid={patientUuid} />}
      requiredFields={['firstName', 'lastName', 'birthday', 'email', 'phone']}
      validationSchema={validationSchema}
      size="M"
    >
      <ConnectedTextInput
        name="firstName"
        label={`${t('demographic.firstName')}*`}
        textContentType="givenName"
        testID="FirstName"
      />
      <ConnectedTextInput
        name="preferredFirstName"
        label={t('demographic.preferredFirstName')}
        textContentType="nickname"
        testID="PreferredFirstName"
      />
      <ConnectedTextInput
        name="lastName"
        label={`${t('demographic.lastName')}*`}
        textContentType="familyName"
        testID="LastName"
      />
      <ConnectedDateInput
        name="birthday"
        label={`${t('demographic.birthday')}*`}
        testID="BirthDate"
        format="MM/DD/YY"
      />
      <ConnectedTextInput
        name="email"
        label={`${t('demographic.email')}*`}
        inputMode="email"
        textContentType="emailAddress"
        testID="Email"
      />
      <ConnectedPhoneNumberInput
        name="phone"
        label={`${t('demographic.cell')}*`}
        textContentType="telephoneNumber"
        testID="Cell"
      />
      <ConnectedCheckbox name="isOptedIntoSms" label={() => <SmsLabel />} testID="IsOptedIntoSms" />
      <Text style={styles.requiredNotice}>{`* ${t('demographic.required')}`}</Text>
    </MainForm>
  );
};
