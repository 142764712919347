import React, { useCallback, useEffect } from 'react';

import { useTranslation } from '@almond/localization';
import { Text, useTheme } from '@almond/ui';
import { useRecoilValue, useSetRecoilState } from 'recoil';

import { Stars } from '~assets';
import { ContainerWithSidebar } from '~modules/layout';
import { useAsync } from '~modules/promises';
import { useRouteNavigation } from '~modules/routing';
import { appointmentParamsAtom, creditCardAtom, patientProgressAtom } from '~modules/state';
import { NotificationStatusBar } from '~modules/ui';

import { Content } from './Content';
import { SidebarContent } from './SidebarContent';
import { useReapplyFirstMonthFree } from './useReapplyFirstMonthFree';

import themedStyles from './styles';

import type { PRICING_EXPLANATION_PAGE_NAME } from '~types';

export const PricingExplanationPage: React.FC = () => {
  const { dispatch } = useRouteNavigation<typeof PRICING_EXPLANATION_PAGE_NAME>();
  const { doAsync, isLoading } = useAsync();
  const { t } = useTranslation();
  const [styles] = useTheme(themedStyles);
  const { subscription } = useRecoilValue(patientProgressAtom);
  const { isNewMemberRemote } = useRecoilValue(appointmentParamsAtom);
  const isResubscribe = !subscription && !isNewMemberRemote;
  const setCreditCardState = useSetRecoilState(creditCardAtom);

  useEffect(() => {
    if (isResubscribe) {
      setCreditCardState(prev => ({ ...prev, promotionCode: undefined }));
    }
  }, [isResubscribe, setCreditCardState]);

  // If a user accidentally clears FIRSTMONTHFREE code or clicks a link with an invalid code,
  // they can go back to the PricingExplanation page and the code will be reapplied
  useReapplyFirstMonthFree(isResubscribe);

  const handleSubmit = useCallback(() => {
    const toCall = () => dispatch('submit');

    doAsync(toCall);
  }, [doAsync, dispatch]);

  return (
    <ContainerWithSidebar
      id="pricingExplanation"
      statusBar={
        isResubscribe && <NotificationStatusBar type="info" content={t('pricingExplanation.resubscribeBanner')} />
      }
      content={<Content onSubmitPress={handleSubmit} isLoading={isLoading} />}
      sidebarContent={<SidebarContent />}
      title={
        <Text
          testID={`pricingExplanation:${t('pricingExplanation.title')}`}
          role="heading"
          aria-level={1}
          fontStyle="medium"
          size="xxxl"
          style={styles.centerText}
        >
          <Stars style={styles.stars} />
          {t('pricingExplanation.title')}
        </Text>
      }
      subtitle={t('pricingExplanation.subtitle')}
    />
  );
};
