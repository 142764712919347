import React, { useEffect, useState } from 'react';
import { StyleSheet, View } from 'react-native';

import { useTranslation } from '@almond/localization';
import { ActivityIndicator, Text } from '@almond/ui';
import { useLocalSearchParams } from 'expo-router';

import { logError, sendMessageToParentFrame } from '~modules/curated';

const styles = StyleSheet.create({
  // eslint-disable-next-line react-native/no-color-literals
  container: {
    backgroundColor: 'var(--color-light-secondary-background)',
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    paddingHorizontal: 50,
  },
  errorText: {
    textAlign: 'center',
  },
});

// Will work a bit better once ENG-4127 merges
export default function TypeformResponse() {
  const searchParams = useLocalSearchParams();
  const [inIframe, setInIframe] = useState(true);
  const { t } = useTranslation();

  useEffect(() => {
    try {
      sendMessageToParentFrame({
        almondMessage: true,
        typeform: 'infection_questionnaire',
        outcome: searchParams.outcome,
      });
    } catch (e) {
      // Either outcome string didn't match, or window is already in top frame
      // TODO handle outcome string not matching
      setInIframe(false);
      logError('Typeform accessed outside iframe');
    }
  }, [searchParams]);

  if (inIframe) {
    return (
      <View style={styles.container}>
        <ActivityIndicator />
      </View>
    );
  }

  // If someone accesses the typeform outside Demi, show an error message
  return (
    <View style={styles.container}>
      <Text style={styles.errorText} size="l">
        {t('typeformResponse.errorText')}
      </Text>
    </View>
  );
}
