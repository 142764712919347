import type { BaseParams, BaseParamsWithoutRouting } from '~types';

const allowedParams = [
  'utm_campaign',
  'utm_content',
  'utm_medium',
  'utm_source',
  'utm_term',
  '_gl',
  'fbclid',
  'is_admin',
  'artemis_redirect_url',
  'is_new_member',
  'appointment_uuid',
  'booking_uuid',
  'patient_uuid',
  'profile_uuid',
  'email',
  'refer',
  'code',
  'category',
] as const;

const allowedSearchParams: (keyof BaseParams)[] = [...allowedParams, 'visit_reason'];
const allowedQueryParams = [...allowedParams];

export const getAllowedSearchParams = (searchParams: BaseParamsWithoutRouting) => {
  return (Object.keys(searchParams) as (keyof BaseParams)[]).reduce((acc, cur) => {
    if (allowedSearchParams.includes(cur)) {
      return { ...acc, [cur]: searchParams[cur as keyof BaseParamsWithoutRouting] };
    }

    return acc;
  }, {} as BaseParamsWithoutRouting);
};

export const getAllowedInitialSearchParams = (searchParams: URLSearchParams) => {
  const qp: BaseParamsWithoutRouting = {};

  [...searchParams.keys()]
    .filter((key: string): key is keyof BaseParamsWithoutRouting => (allowedQueryParams as string[]).includes(key))
    .forEach(key => {
      // Does not support arrays in search params
      // TODO verify at runtime this matches the correct type
      qp[key] = searchParams.get(key) as any;
    });

  return qp;
};
