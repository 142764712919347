import { StyleSheet } from 'react-native';

import { getShadow, sizes, styleSheetFactory } from '@almond/ui';

export default styleSheetFactory(theme => ({
  contentStyles: {
    backgroundColor: theme.lightSecondaryBackground,
  },
  mobileTitle: {
    textAlign: 'left',
    width: '100%',
    paddingHorizontal: sizes.SPACING_L,
  },
  formWrapper: {
    width: '100%',
    maxWidth: 600,
    marginVertical: sizes.SPACING_XL,
    paddingHorizontal: sizes.SPACING_L,
  },
  formWrapperMobile: {
    width: '100%',
    marginTop: sizes.SPACING_S,
  },
  formWrapperInner: {
    rowGap: sizes.SPACING_M,
  },
  button: {
    alignSelf: 'center',
  },
  // Side Panel
  sidePanelContainer: {
    margin: sizes.SPACING_XL,
  },
  codeEntryForm: {
    marginTop: sizes.SPACING_L,
    flexDirection: 'row',
    gap: sizes.SPACING_S,
  },
  codeEntryInput: {
    flex: 1,
  },
  enteredCodesContainer: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    gap: sizes.SPACING_S,
  },
  enteredCode: {
    backgroundColor: theme.darkSecondaryBackground,
    alignSelf: 'flex-start',
    flexDirection: 'row',
    borderRadius: sizes.SPACING_XS,
    alignItems: 'center',
  },
  enteredCodeButton: {
    padding: sizes.SPACING_S,
  },
  enteredCodeText: {
    padding: sizes.SPACING_S,
    paddingRight: 0,
    textTransform: 'uppercase',
  },
  priceContainer: {
    marginTop: sizes.SPACING_L,
    gap: sizes.SPACING_M,
  },
  priceContainerItem: {
    flexDirection: 'row',
    width: '100%',
    alignItems: 'center',
  },
  priceContainerLeftColumn: {
    flexGrow: 1,
    flexBasis: 'auto',
  },
  priceContainerRightColumn: {
    flexGrow: 0,
    flexBasis: 'auto',
  },
  cartItem: {
    flexDirection: 'row',
    columnGap: 12,
    height: 50,
    width: '100%',
    maxWidth: '100%',
  },
  cartItemImageContainer: {
    ...getShadow(),
    borderRadius: sizes.SPACING_XS,
    height: '100%',
  },
  cartItemImage: {
    aspectRatio: 1,
    transform: [{ scaleX: -1 }],
    height: '100%',
    width: 50,
  },
  cartItemMain: {
    flex: 1,
  },
  accordion: {
    borderBottomColor: theme.border,
    borderBottomWidth: StyleSheet.hairlineWidth,
  },
  accordionToggle: {
    backgroundColor: theme.lightSecondaryBackground,
    flexDirection: 'row',
    paddingHorizontal: sizes.SPACING_L,
    paddingVertical: sizes.SPACING_M,
    width: '100%',
  },
  accordionPrice: {
    marginLeft: 'auto',
    flexDirection: 'row',
    gap: sizes.SPACING_S,
    alignItems: 'center',
  },
  termsText: {
    color: theme.text,
  },
}));
