import { getShadow, sizes, styleSheetFactory } from '@almond/ui';

export default styleSheetFactory(theme => ({
  container: {
    padding: sizes.SPACING_M,
  },

  description: {
    backgroundColor: theme.background,
    padding: sizes.SPACING_L,
    borderRadius: sizes.SPACING_S,
    marginTop: sizes.SPACING_M,
  },

  buttons: {
    marginVertical: sizes.SPACING_M,
  },

  stars: {
    marginTop: -sizes.SPACING_XXL,
  },

  subscription: {
    ...getShadow(),
    backgroundColor: theme.background,
    width: 338,
    paddingVertical: 20,
    borderRadius: sizes.SPACING_M,
  },

  fullWidth: {
    width: '100%',
  },

  annualContainer: {
    backgroundColor: theme.selectedOption,
    paddingVertical: sizes.SPACING_XS,
    paddingHorizontal: sizes.SPACING_S,
    borderRadius: sizes.SPACING_S,
    width: 82,
    alignSelf: 'center',
    alignItems: 'center',
  },

  annual: {
    color: theme.lightText,
  },

  priceContainer: {
    flexDirection: 'row',
    alignSelf: 'center',
    marginTop: sizes.SPACING_M,
  },

  oldPrice: {
    textDecorationLine: 'line-through',
    marginEnd: sizes.SPACING_S,
  },

  perYear: {
    alignSelf: 'flex-end',
    marginBottom: sizes.SPACING_XXS,
  },

  firstYear: {
    marginStart: sizes.SPACING_S,
  },

  limitedPromo: {
    color: theme.accent,
    textAlign: 'center',
    marginTop: sizes.SPACING_XS,
    paddingHorizontal: sizes.SPACING_L,
    marginHorizontal: 'auto',
    maxWidth: 360,
  },

  subscriptionItemsTitle: {
    marginTop: sizes.SPACING_M,
    paddingHorizontal: sizes.SPACING_L,
  },

  subscriptionItemsList: {
    paddingHorizontal: sizes.SPACING_L,
    marginTop: sizes.SPACING_S,
  },

  button: {
    marginTop: 56,
    alignSelf: 'center',
    width: '85%',
  },

  returningMember: {
    textAlign: 'center',
    color: theme.secondaryTextDark,
    alignSelf: 'center',
    marginTop: sizes.SPACING_M,
  },

  centerText: {
    textAlign: 'center',
  },

  selfCenter: {
    alignSelf: 'center',
  },

  errorMessage: {
    marginVertical: sizes.SPACING_M,
  },

  disclaimer: {
    marginTop: sizes.SPACING_S,
    textAlign: 'center',
    paddingHorizontal: sizes.SPACING_L,
  },
}));
