import {
  INFECTION_QUESTIONNAIRE_PAGE_NAME,
  INFECTION_REVIEWING_PAGE_NAME,
  INFECTION_URGENT_CARE_PAGE_NAME,
  RECOMMENDATION_PAGE_NAME,
  SCHEDULING_PAGE_NAME,
} from '~types';

import { bookingRoutes } from './booking';

import type { RoutingConfig, StackParamList } from '~types';

export const curatedFlowRoutes = {
  id: 'curatedFlow' as const,
  initial: () => ({ name: INFECTION_QUESTIONNAIRE_PAGE_NAME }),
  routes: {
    [INFECTION_QUESTIONNAIRE_PAGE_NAME]: {
      on: {
        npPaVisit: () => ({ name: RECOMMENDATION_PAGE_NAME, params: { infectionOutcome: 'true' } }),
        reviewing: () => ({ name: INFECTION_REVIEWING_PAGE_NAME }),
        urgentCare: () => ({ name: INFECTION_URGENT_CARE_PAGE_NAME }),
      },
    },
    [RECOMMENDATION_PAGE_NAME]: {
      on: {
        submit: () => ({ name: SCHEDULING_PAGE_NAME, params: { infectionOutcome: 'true' } }),
        curatedSubmit: () => {
          throw new Error('Not yet implemented');
        },
        secondary: () => ({ name: SCHEDULING_PAGE_NAME, params: { infectionOutcome: 'true', showAll: 'true' } }),
      },
    },
    [SCHEDULING_PAGE_NAME]: {
      on: {
        submit: () => bookingRoutes,
        curatedSubmit: () => {
          throw new Error('Not yet implemented');
        },
      },
    },
    [INFECTION_REVIEWING_PAGE_NAME]: {
      on: {},
    },
    [INFECTION_URGENT_CARE_PAGE_NAME]: {
      on: {},
    },
  },
} satisfies RoutingConfig<StackParamList>;
