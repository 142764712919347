import React, { useMemo } from 'react';

import { useTranslation } from '@almond/localization';
import { ActivityIndicator, Button, useTheme } from '@almond/ui';
import { useLocalSearchParams } from 'expo-router';
import { useRecoilValue } from 'recoil';

import { GET_INSURANCE_PATH, patientsApi, useFocusedSWR } from '~modules/api';
import { useRedirectBackToArtemis } from '~modules/integration';
import { Container } from '~modules/layout';
import { useRouteNavigation } from '~modules/routing';
import { patientProgressAtom } from '~modules/state';
import { ConfettiBurstsDecoration, GradientButton, MessageWithImageV2 } from '~modules/ui';

import themedStyles from './styles';

import type { CONFIRMED_PAGE_NAME, ConfirmedParams } from '~types';

export const ConfirmedPage: React.FC = () => {
  const { t } = useTranslation();
  const { dispatch } = useRouteNavigation<typeof CONFIRMED_PAGE_NAME>();
  const [styles] = useTheme(themedStyles);
  const { isLoading, data } = useFocusedSWR(GET_INSURANCE_PATH, patientsApi.retrieveInsurance, {
    revalidateOnFocus: false,
  });
  // If it 404s (data is undefined, error is defined), assume user does not have insurance
  const insuranceData = useMemo(() => data ?? { memberId: undefined, isAccepted: false }, [data]);
  const searchParams = useLocalSearchParams<ConfirmedParams>();
  const isEstablishedMember = searchParams.is_new_member !== 'true';
  // This flag represents the state when the current patient can open a next page.
  // It's false when the patient who didn't confirm her email skipped adding an insurance.
  const canContinue = (searchParams.can_continue == null ? 'true' : searchParams.can_continue) === 'true';
  const patientProgressState = useRecoilValue(patientProgressAtom);
  const isAllSet =
    patientProgressState.historyData &&
    patientProgressState.pharmacy &&
    patientProgressState.address &&
    patientProgressState.referral;
  const redirectBackToArtemis = useRedirectBackToArtemis();
  const subtitle = useMemo(() => {
    if (!canContinue) return t('confirmEmail.message.subtitle');

    return t(isAllSet ? 'confirmed.allSet.subtitle' : 'confirmed.message.subtitle');
  }, [canContinue, isAllSet, t]);
  const footer = useMemo(() => {
    if (!canContinue) return null;

    return isAllSet ? (
      <Button onPress={() => redirectBackToArtemis()} size="m" testID="Submit">
        {t('backToDashboard')}
      </Button>
    ) : (
      <GradientButton onPress={() => dispatch('submit', insuranceData)} testID="Submit" size="m">
        {t(`confirmed.${isEstablishedMember ? 'established' : 'new'}.submitTitle`)}
      </GradientButton>
    );
  }, [canContinue, insuranceData, dispatch, isAllSet, isEstablishedMember, redirectBackToArtemis, t]);

  if (isLoading) {
    return <ActivityIndicator />;
  }

  return (
    <Container
      id="confirmed"
      contentContainerStyle={styles.contentContainer}
      backgroundDecoration={<ConfettiBurstsDecoration />}
      shouldHideCloseButton
    >
      <MessageWithImageV2
        image="relaxingWoman"
        imageDimensions={{ width: 634, height: 381 }}
        mobileImageDimensions={{ width: 253, height: 152 }}
        title={t('confirmed.message.title')}
        subtitle={subtitle}
        footer={footer}
        isMessageOverImage
      />
    </Container>
  );
};
