export const ALL_SET_PAGE_NAME = '/all-set' as const;
export const CONSENT_SIGNATURE_PAGE_NAME = '/consent-signature' as const;
export const CREATE_PATIENT_PAGE_NAME = '/create-patient' as const;
export const GET_STARTED_PAGE_NAME = '/get-started' as const;
export const PATIENT_LOGIN_PAGE_NAME = '/login' as const;

export type AllSetParams = undefined;
export type ConsentSignatureParams = undefined;
export type CreatePatientParams = undefined;
export type GetStartedParams = undefined;
export type PatientLoginParams = undefined;
