import { z } from 'zod';

export const SANDBOX_RESPONSE_ID = '__sandbox';

export const typeformMessage = z.object({
  almondMessage: z.literal(true),
  typeform: z.literal('infection_questionnaire'),
  outcome: z.enum(['npPaVisit', 'reviewing', 'urgentCare']),
});

// When the Typeform is live, it will have a destination URL at a specific
// domain. That Typeform should be able to run on prod, localhost,
// staging, and Firebase preview URLs
const allowedDomains = [
  'http://localhost:19006',
  'https://local.stage-almondobgyn.com:3000',
  'https://book.stage-almondobgyn.com',
  'https://book.almondobgyn.com',
  /^https:\/\/almond-health-stage--pr[^.]+\.web\.app$/,
];

export const isOriginAllowed = (origin: string) => {
  return allowedDomains.some(toCheck => {
    if (typeof toCheck === 'string') {
      return toCheck === origin;
    }

    return toCheck.test(origin);
  });
};

export const originMessage = z.object({
  almondMessage: z.literal(true),
  type: z.literal('origin'),
  origin: z.string(),
});
