import React, { useMemo } from 'react';
import { View } from 'react-native';

import { useTranslation } from '@almond/localization';
import { Button, IconButton, useAdaptive, useBrowserTypeMap, useTheme } from '@almond/ui';
import { useLocalSearchParams } from 'expo-router';

import { AbandonModalWrapper } from '../AbandonModalWrapper';
import { ConfirmationModal } from './ConfirmationModal';
import { useCloseButton } from './useCloseButton';

import themedStyles from './styles';

export type CloseButtonProps = {
  isVisible?: boolean;
};

const CloseButton: React.FC<CloseButtonProps> = props => {
  const { t } = useTranslation();
  const [styles] = useTheme(themedStyles);
  const buttonContainerStyle = useAdaptive(styles.buttonContainer, {});
  const { onPress, text, isModalVisible, toggleModal, onLeavePress } = useCloseButton();
  const closeButtonComponent = useAdaptive(
    // Desktop
    <Button onPress={onPress} size="s" fontStyle="bold" type="accent" fixedWidth={false}>
      {t(text)}
    </Button>,
    // Mobile
    <IconButton source="close" color="text" onPress={onPress} />
  );
  const { isDesktop } = useBrowserTypeMap();
  const searchParams = useLocalSearchParams();
  const modals = useMemo(() => {
    if (searchParams.booking_uuid) {
      return <ConfirmationModal isVisible={isModalVisible} onRequestClose={toggleModal} onLeavePress={onLeavePress} />;
    }

    return <AbandonModalWrapper isVisible={isModalVisible} onRequestClose={toggleModal} onLeave={onLeavePress} />;
  }, [searchParams.booking_uuid, isModalVisible, onLeavePress, toggleModal]);

  if (!props.isVisible) return <View style={buttonContainerStyle} testID="FakeButton" />;

  return (
    <View
      style={[buttonContainerStyle, styles.buttonContainerRight, isDesktop && styles.buttonContainerRightDesktop]}
      testID="Close"
    >
      {closeButtonComponent}
      {modals}
    </View>
  );
};

export default CloseButton;
